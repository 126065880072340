<template>
    <v-main>
        <div class="pt-6 pb-6 pr-3 pl-3">
            <v-container>
                <v-row>
                    <v-col xs="12" sm="9" md="6">
                        <v-form :validate-on-blur="true" ref="form" class="ml-6 mt-6 form-request-search" v-model="valid">
                            <v-row>
                                <legend>
                                    Preencha com o <strong>CPF</strong> ou <strong>CNPJ</strong> que
                                    queira solicitar limite
                                </legend>
                            </v-row>
                            <v-row class="mt-6">
                                <v-text-field
                                    v-model="cpfCnpjField"
                                    :rules="[cpfCnpjRules.required, cpfCnpjRules.minLength]"
                                    label="CPF/CNPJ"
                                    @keyup="dismissFindByDocumentStatus()"
                                    class="v-input-default"
                                    v-mask="maskcpfCnpjField"
                                    :validate-on-blur="true"
                                ></v-text-field>
                                <v-btn
                                    :disabled="!valid || findByDocumentStatus === 'START'"
                                    title="Enviar"
                                    color="secondary"
                                    depressed
                                    @click="validate"
                                >
                                    Enviar
                                </v-btn>
                            </v-row>

                            <Loader
                                align="center"
                                justify="center"
                                v-if="findByDocumentStatus === RequestStatusEnum.START"
                            />
                        </v-form>
                    </v-col>
                </v-row>
            </v-container>

            <v-container>
                <v-row class="ml-3 mt-3 mr-3" v-if="showCreateForm">
                    <Form
                        @onSubmit="newRegister"
                        :segmentsList="segments"
                        :typeForm="typeForm"
                        :currentDocument="currentDocument"
                    />
                </v-row>

                <Loader
                    align="center"
                    justify="center"
                    v-if="createRegisterStatus === RequestStatusEnum.START"
                    mode="overlay"
                />
            </v-container>
        </div>
    </v-main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Form from '../components/_formSolicitacao';
import { notification } from '@/helpers/notification';
import { RequestStatusEnum } from '@/helpers/consts';
import Loader from '@/components/base/Loader';

export default {
    data: () => ({
        valid: true,
        cpfCnpjField: '',
        maskcpfCnpjField: '#################',
        cpfCnpjRules: {
            required: value => !!value || 'CPF/CNPJ obrigatório',
            minLength: v => (v && v.length >= 10 ? true : 'Deve ter no mínimo 10 caracteres'),
        },
        typeForm: 'new',
        currentDocument: null,
        showCreateForm: false,
        RequestStatusEnum,
    }),

    components: {
        Form,
        Loader,
    },

    computed: {
        ...mapGetters('credito', {
            findByDocumentStatus: 'findByDocumentStatus',
            createRegisterStatus: 'createRegisterStatus',
            segments: 'segments',
            selectedProduct: 'selectedProduct',
        }),
    },
    watch: {
        findByDocumentStatus(newValue) {
            if (newValue === RequestStatusEnum.START) {
                this.showCreateForm = false;
                return;
            }
            if (newValue.type === RequestStatusEnum.ERROR) {
                if (newValue.httpStatus !== 404) {
                    notification(
                        this.$dialog.notify.error,
                        'Ocorreu um erro ao efetuar a busca: ' + newValue.message
                    );
                    return;
                }
                this.currentDocument = {
                    document: this.cpfCnpjField,
                };
                this.showCreateForm = true;
                return;
            }
            if (newValue === RequestStatusEnum.SUCCESS) {
                notification(this.$dialog.notify.success, 'Cadastro encontrado');
                this.dismissAllFindByDocument();
                this.$router.push(`/admin/credito/limites/solicitar/${this.cpfCnpjField}/valor`);
            }
        },
        createRegisterStatus(newValue) {
            if (newValue.type === RequestStatusEnum.ERROR) {
                notification(
                    this.$dialog.notify.error,
                    'Desculpe-nos, ocorreu um erro ao processar o cadastro: ' + newValue.message
                );
                return;
            }
            if (newValue === RequestStatusEnum.SUCCESS) {
                notification(this.$dialog.notify.success, 'Cadastro efetuado com sucesso');
                this.dismissFindByDocumentStatus();
                this.$router.push(
                    `/admin/credito/limites/solicitar/${this.cpfCnpjField}/valor?new=true`
                );
                return;
            }
        },
    },
    mounted() {
        this.dismissAllFindByDocument();
        this.getSegments(this.selectedProduct);
    },
    methods: {
        ...mapActions('credito', {
            submitRegister: 'submitRegister',
            getSegments: 'getSegments',
            findByDocument: 'findByDocument',
            dismissFindByDocumentStatus: 'dismissFindByDocumentStatus',
            dismissAllFindByDocument: 'dismissAllFindByDocument',
        }),
        validate() {
            this.findByDocument(this.cpfCnpjField);
        },
        newRegister(formData) {
            const data = {
                ...formData,
                status: 1,
                root: formData.document,
                fantasyName: formData.name,
                type: 'ROLE_CLIENTE',
                password: 'password',
                config: {
                    segment: formData.segment,
                },
            };
            this.submitRegister({ id: this.selectedProduct.id, data });
        },
    },
};
</script>
<style scoped lang="scss">
@import './Solicitacao.scss';
</style>